import React, { useEffect, useState } from 'react';
import './Voucher.css';
import VoucherActivationModal from './VoucherActivationModal';
import AOS from "aos";

const Voucher = () => {
    const [nick, setNick] = useState('');
    const [voucherCode, setVoucherCode] = useState('');
    const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);
    const [activationSuccess, setActivationSuccess] = useState(false);
    const [activationErrorMessage, setActivationErrorMessage] = useState('');
    const [nickError, setNickError] = useState('');
    const [voucherCodeError, setVoucherCodeError] = useState('');

    const handleActivateVoucher = () => {
        if (!nick || !voucherCode) return;
        validateVoucherCode(voucherCode);
        validateNick(nick);
        if (!voucherCodeError && !nickError) {
            const isValid = checkVoucherValidity(voucherCode);
            if (isValid) {
                setIsActivationModalOpen(true);
                setActivationSuccess(true);
            } else {
                setActivationSuccess(false);
                setActivationErrorMessage('Niepoprawny kod vouchera.');
                setIsActivationModalOpen(true);
            }
        }
    };

    const checkVoucherValidity = (voucherCode) => {
        // Jeśli tak zwroc true jesli nie zwroc false
        const testVoucherCode = "TEST123456";
        return voucherCode === testVoucherCode;
    };


    const validateVoucherCode = (value) => {
        const regex = /^[a-zA-Z0-9]*$/;
        if (value.length > 10) {
            setVoucherCodeError('Kod vouchera powinien zawierać co najwyżej 10 znaków.');
        } else if (!regex.test(value)) {
            setVoucherCodeError('Kod vouchera powinien zawierać wyłącznie litery i cyfry.');
        } else {
            setVoucherCodeError('');
        }
    };

    const validateNick = (value) => {
        const regex = /^[a-zA-Z0-9]{1,32}$/;
        if (value && !regex.test(value)) {
            setNickError('Nick powinien zawierać wyłącznie litery i cyfry oraz mieć maksymalnie 32 znaki.');
        } else {
            setNickError('');
        }
    };

    useEffect(() => {
        AOS.init({
            duration: 500,
            easing: 'ease',
            once: false,
        });
    }, []);

    return (
        <div className="voucher-wrapper">
            <div className="voucher">
                <div className="title2" data-aos="zoom-in-up">
                    <h2>Aktywuj Voucher</h2>
                </div>
                <hr className="voucher-line" data-aos="zoom-in-up" />
                <div className="container-voucher-out" data-aos="zoom-in-up">
                    <div className="container-voucher" data-aos="zoom-in-up">
                        <VoucherActivationModal
                            isOpen={isActivationModalOpen}
                            onClose={() => setIsActivationModalOpen(false)}
                            nick={nick}
                            voucherCode={voucherCode}
                            success={activationSuccess}
                            errorMessage={activationErrorMessage}
                        />
                        <div className="form-group2">
                            <label className="subtitle">NAZWA GRACZA</label>
                            <input
                                type="text"
                                value={nick}
                                onChange={(e) => {
                                    setNick(e.target.value);
                                    validateNick(e.target.value);
                                }}
                                placeholder="Wpisz swój nick"
                            />
                            {nickError && <div className="error">{nickError}</div>}
                        </div>
                        <div className="form-group2">
                            <label className="subtitle">KOD VOUCHERA</label>
                            <input
                                type="text"
                                value={voucherCode}
                                onChange={(e) => {
                                    setVoucherCode(e.target.value);
                                    validateVoucherCode(e.target.value);
                                }}
                                placeholder="Wpisz kod vouchera"
                            />
                            {voucherCodeError && <div className="error">{voucherCodeError}</div>}
                        </div>
                        <button className="button-voucher"
                                onClick={handleActivateVoucher}
                                disabled={!nick || voucherCode.length !== 10 || !!nickError || !!voucherCodeError}
                        >
                            Aktywuj Voucher
                        </button>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Voucher;
