import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './component/Home';
import NotFound from './component/NotFound';
import Rules from './component/Rules';
import Footer from "./Footer";
import NavigationBar from "./NavigationBar";
import Payments from "./component/Payments";
import Voucher from "./component/Voucher"


function App() {
  return (
    <Router>


        <NavigationBar/>
      <Helmet>
          <title>WizardCraft.pl - Strona Głowna</title>
          <meta name="description" content="WizardCraft.pl - Serwer o wielu możliwościach" />
          <link rel="canonical" href="https://wizardcraft.pl/" />
      </Helmet>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/regulamin" element={<Rules />} />
            <Route path="/sklep" element={<Payments />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/voucher" element={<Voucher />} />
        </Routes>
     <Footer/>
    </Router>
  );
}

export default App;
