import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import './VoucherActivationModal.css';

const VoucherActivationModal = ({ isOpen, onClose, nick, voucherCode, success, errorMessage }) => {
    const navigate = useNavigate();

    const handleCloseAndRedirect = () => {
        onClose();
        navigate('/');
        document.body.style.overflow = 'auto';
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => {}}
            className="voucher-activation-modal"
            overlayClassName="voucher-activation-modal-overlay"
        >
            <div className="voucher-activation-content">
                {success ? (
                    <>
                        <h2>Voucher został pomyślnie aktywowany!</h2>
                        <h3>Oto szczegóły aktywacji:</h3>
                        <p>Nick: {nick}</p>
                        <p>Kod vouchera: {voucherCode}</p>
                        <h3>Teraz możesz cieszyć się korzyściami z vouchera!</h3>
                    </>
                ) : (
                    <>
                        <h2>Niepowodzenie aktywacji vouchera</h2>
                        <p>{errorMessage}</p>
                    </>
                )}
                <button className="button-payments"onClick={handleCloseAndRedirect}>Powrót na stronę główną</button>
            </div>
        </Modal>
    );
};

export default VoucherActivationModal;