import React, {useEffect, useState} from 'react';
import './Rules.css';
import AOS from "aos";

const Rules = () => {
  const [currentRule, setCurrentRule] = useState('sklep');

  const showRule = (rule) => {
    setCurrentRule(rule);
  };


  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: 'ease',
      once: false,
    });
  }, []);

  return (
      <div className="rules-wrapper">
        <div className="rules" data-aos="zoom-in-up">
          <div className="title4" data-aos="zoom-in-up">
            <h2>REGULAMIN</h2>
          </div>
          <hr className="rules-line" data-aos="zoom-in-up"/>
          <div className="container-rules-out">
            <div className="container-rules" data-aos="zoom-in-up">
              <div className="button-group">
                <button
                    className={currentRule === 'sklep' ? 'rule-button active' : 'rule-button'}
                    onClick={() => showRule('sklep')}
                >
                  REGULAMIN SKLEPU
                </button>
                <button
                    className={currentRule === 'serwer' ? 'rule-button active' : 'rule-button'}
                    onClick={() => showRule('serwer')}
                >
                  REGULAMIN SKLEPU
                </button>
              </div>
              <div className="rule-content">
                <div className="rule-description">
                  {currentRule === 'sklep' && (
                      <>
                        <h2>1. Postanowienia ogólne</h2>
                        <p className="indent">1.1 Sklep internetowy WizardCraft.pl jest prowadzony przez osobę fizyczn,
                          niezarejestrowaną jako działalność gospodarcza.</p>
                        <p className="indent">1.2 Operator płatności paybylink.pl obsługuje transakcje dokonywane w
                          sklepie.</p>
                        <p className="indent">1.3 Wszystkie transakcje i działania w sklepie odbywają się
                          automatycznie.</p>
                        <p className="indent">1.4 Dostępnymi formami płatności w serwisie są: PaySafeCard, SMS, Przelew
                          oraz Direct Billing.</p>

                        <h2>2. Definicje</h2>
                        <p className="indent">2.1 Sklep - strona internetowa WizardCraft.pl, dostępna pod adresem
                          https://WizardCraft.pl, umożliwiająca nabywanie usług związanych z serwerem Minecraft.</p>
                        <p className="indent">2.2 Klient - osoba fizyczna korzystająca z usług sklepu WizardCraft.pl.</p>
                        <p className="indent">2.3 Serwer - serwer gry Minecraft prowadzony przez Administratora,
                          dostępny
                          pod adresem WizardCraft.pl.</p>
                        <p className="indent">2.4 Produkt - usługa świadczona przez Administratora poprzez serwer
                          Minecraft na rzecz Klienta.</p>
                        <p className="indent">2.5 Konto Klienta - indywidualne konto utworzone przez Klienta na serwerze
                          Minecraft WizardCraft.pl.</p>

                        <h2>3. Warunki korzystania z usług</h2>
                        <p className="indent">3.1 Aby zakupić i skorzystać z Produktów oferowanych w sklepie, Klient
                          musi
                          posiadać Konto Klienta oraz wystarczające środki finansowe w walucie podanej przy cenie danego
                          Produktu.</p>
                        <p className="indent">3.2 Warunki korzystania z usług określone są w Regulaminie świadczenia
                          usług.</p>
                        <p className="indent">3.3 Po przetworzeniu Płatności i otrzymaniu potwierdzenia o prawidłowej
                          realizacji, wybrana przez Klienta usługa zostanie niezwłocznie aktywowana na Koncie
                          Klienta.</p>
                        <p className="indent">3.4 Klient ponosi pełną odpowiedzialność za poprawność przekazywanych
                          danych
                          podczas składania zamówienia oraz za prawidłowe skorzystanie z Produktu.</p>

                        <h2>4. Zamówienia</h2>
                        <p className="indent">4.1 Informacje prezentowane w Sklepie stanowią ofertę Sprzedawcy dotyczącą
                          zawarcia umowy sprzedaży usługi.</p>
                        <p className="indent">4.2 Klient może przyjąć ofertę w dowolnym czasie, 24 godziny na dobę, 7
                          dni
                          w tygodniu, korzystając z dostępnych metod płatności. Instrukcje dotyczące procesu zakupu są
                          zamieszczone przy każdym Produkcie.</p>
                        <p className="indent">4.3 Ceny podane przy Produktach w Sklepie są cenami brutto wyrażonymi w
                          polskich złotych (PLN).</p>
                        <p className="indent">4.4 Zamówione usługi zostaną aktywowane na Koncie Klienta po prawidłowym
                          przetworzeniu Płatności.</p>

                        <h2>5. Rezygnacja z usługi</h2>
                        <p className="indent">5.1 Klient ma prawo do odstąpienia od umowy bez podania przyczyny,
                          składając
                          stosowne oświadczenie na piśmie w terminie 14 dni od dnia zakupu. Oświadczenie należy wysłać
                          na
                          adres korespondencyjny Administratora.</p>
                        <p className="indent">5.2 Prawo odstąpienia od umowy nie przysługuje Klientowi w przypadku umów
                          o
                          dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym, jeżeli
                          świadczenie
                          rozpoczęło się za wyraźną zgodą Klienta przed upływem terminu do odstąpienia od umowy.</p>
                        <p className="indent">5.3 Prawo odstąpienia od umowy nie przysługuje Klientowi w odniesieniu do
                          umów o świadczenie usług, jeżeli Sprzedawca wykonał w pełni usługę za wyraźną zgodą
                          Klienta.</p>

                        <h2>6. Reklamacje</h2>
                        <p className="indent">6.1 Klient ma prawo zgłosić reklamację dotyczącą realizacji umowy
                          sprzedaży
                          poprzez kontakt z Sprzedawcą na adres korespondencyjny Administratora. Sprzedawca rozpatruje
                          reklamacje w terminie 14 dni od ich zgłoszenia i udziela odpowiedzi na adres podany przez
                          Klienta.</p>

                        <h2>7. Polityka prywatności</h2>
                        <p className="indent">7.1 W ramach umowy powierzenia danych, dane osobowe państwa są
                          przetwarzane
                          przez Administratora, który jest zarówno wydawcą, jak i administratorem serwisów internetowych
                          i
                          usług, zgodnie z RODO. Administrator jest Podmiotem Przetwarzającym Dane, a połączenie ze
                          sklepem jest szyfrowane, co potwierdza informacja na pasku adresu w państwa przeglądarce.</p>
                        <p className="indent">7.2 Administrator dba o ochronę danych osobowych Klientów przed
                          ujawnieniem
                          osobom nieupoważnionym, utratą, zniszczeniem lub nieuprawnioną modyfikacją za pomocą
                          odpowiednich zabezpieczeń systemowych i technicznych.</p>
                        <p className="indent">7.3 Dane osobowe Klientów są przetwarzane i przechowywane na podstawie
                          art.
                          6 ust. 1 lit. f RODO, co oznacza, że są one przetwarzane tylko wtedy, gdy jest to konieczne do
                          realizacji prawnie uzasadnionych interesów Administratora lub strony trzeciej. Przetwarzane są
                          tylko dane, które są niezbędne do wykonania umowy sprzedaży, a Klient wprowadza je podczas
                          rejestracji w sklepie internetowym/serwerze WizardCraft.pl. Dane osobowe mogą być przetwarzane
                          również do celów marketingowych, ale tylko po uzyskaniu wyraźnej zgody Klienta. Można cofnąć
                          zgodę na przetwarzanie danych w celach marketingowych w dowolnym momencie, przesyłając
                          informację na adres e-mail: pomoc@WizardCraft.pl.</p>
                        <p className="indent">7.4 Klient ma prawo do sprawdzenia swoich danych osobowych oraz do
                          informacji, czy Administrator i Operator przetwarzają je zgodnie z ich przeznaczeniem.</p>
                        <p className="indent">7.5 Klient ma prawo do zażądania od Administratora ograniczenia
                          przetwarzania swoich danych.</p>
                        <p className="indent">7.6 Klient może dokonać zmiany swoich danych poprzez kontakt z
                          administratorem poprzez adres pocztowy lub e-mailowy i podanie zakresu zmian.</p>
                        <p className="indent">7.7 Klient ma prawo do bycia zapomnianym i żądania całkowitego usunięcia
                          swoich danych. W tym celu należy skontaktować się z Administratorem przez adres e-mail:
                          pomoc@WizardCraft.pl</p>
                        <p className="indent">7.8 Klient może wystąpić do Administratora z żądaniem przeniesienia swoich
                          danych osobowych do innego Administratora, do którego przekaże swoje dane.</p>
                        <p className="indent">7.9 Dane osobowe Klientów, uzyskane w celu wykonania umowy, będą
                          przetwarzane tak długo, jak będzie to konieczne do wykonania umowy, a następnie przez czas
                          odpowiadający okresowi przedawnienia roszczeń, które mogą być wystosowane wobec
                          Administratora,
                          czyli roszczenia z tytułu rękojmi, lub roszczenia pochodzące od organów podatkowych (do 5
                          lat).</p>

                        <h2>8. Postanowienia końcowe</h2>
                        <p className="indent">8.1 Administrator przetwarza dane osobowe Klientów zgodnie z obowiązującą
                          Polityką prywatności.</p>
                        <p className="indent">8.2 Serwer Minecraft WizardCraft.pl nie jest powiązany z firmą Mojang AB,
                          producentem gry "Minecraft".</p>
                        <p className="indent">8.3 W przypadku sporów, Klient ma możliwość skorzystania z pozasądowych
                          metod rozstrzygania sporów i dochodzenia roszczeń, takich jak mediacje.</p>
                        <p className="indent">8.4 Administrator zastrzega sobie prawo do wprowadzania zmian w
                          Regulaminie.
                          Wszelkie zmiany będą ogłaszane w Sklepie i w świadczonych usługach z odpowiednim
                          wyprzedzeniem.</p>
                        <p className="indent">8.5 W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie
                          powszechnie obowiązujące przepisy prawa polskiego.</p>
                        <p className="indent">8.6 Właściciel sklepu:Damian Krauze Nierejestrowana Działalność
                          Gospodarcza</p>
                        <p className="indent">9.7 Kontakt: pomoc@WizardCraft.pl".</p>

                        <br/>
                        <p className="center">Data opublikowania regulaminu: 31.05.2024</p>

                      </>
                  )}
                  {currentRule === 'serwer' && (
                      <>
                        <h2>1. Zasady ogólne</h2>
                        <p className="indent">1.1 Kultura i szacunek: Szanuj innych graczy i zachowuj się kulturalnie
                          wobec społeczności serwera.</p>
                        <p className="indent">1.2 Netykieta: Przestrzegaj netykiety, unikaj nadmiernego używania CAPS
                          LOCK
                          i spamowania wiadomościami.</p>
                        <p className="indent">1.3 Ochrona danych osobowych: Nie udostępniaj prywatnych informacji ani
                          danych osobowych innych graczy bez ich zgody.</p>
                        <p className="indent">1.4 Czat i język: Unikaj wulgaryzmów, treści nieodpowiednich, obraźliwych
                          lub wulgarnych nazw graczy.</p>
                        <p className="indent">1.5 Unikaj cheatów: Graj fair i nie korzystaj z oszustw, modów lub
                          nielegalnych modyfikacji, które dają Ci nieuczciwą przewagę.</p>
                        <p className="indent">1.6 Ochrona środowiska: Szanuj środowisko na serwerze, zakazane jest
                          nadmierne niszczenie terenu.</p>
                        <p className="indent">1.7 Handel i ekonomia: Jeśli na serwerze obowiązuje ekonomia, uczciwie
                          przestrzegaj zasad handlu i nie oszukuj innych graczy.</p>
                        <p className="indent">1.8 Nagrywanie i streamowanie: Nagrywanie oraz streamowanie na serwerze
                          jest
                          dozwolone bez konieczności ogólnej zgody, jednakże w przypadku chęci reklamowania się lub
                          promowania swojego kanału/streamu na serwerze, trzeba zakupić rangę (Vip, Svip).
                          Jeżeli jesteś większym streamerem, youtuberem możesz poprosić administracje o specjalną rangę,
                          która ci pozwala na reklamowanie bez podanych rang.</p>
                        <p className="indent">1.9 Pomoc administracji: W przypadku problemów lub złamania zasad,
                          skontaktuj się z administracją serwera i respektuj jej decyzje.</p>

                        <h2>10. Zasady graczy</h2>
                        <p className="indent">2.1 Używanie cheatów i bugów: Ścisły zakaz używania cheatów, nieuczciwych
                          modyfikacji oraz wykorzystywania bugów gry, które dają nieuczciwą przewagę.</p>
                        <p className="indent">2.2 Nękanie i obrażanie: Zakazane jest nękanie, obrażanie i wszelkie
                          zachowania toksyczne wobec innych graczy. Szanuj innych członków społeczności.</p>
                        <p className="indent">2.3 Handel: Handel z innymi graczami powinien być uczciwy. Nie oszukuj w
                          trakcie transakcji.</p>
                        <p className="indent">2.4 Niszczenie świata: Unikaj nadmiernego niszczenia naturalnego świata
                          gry. Dbaj o estetykę i równowagę.</p>
                        <p className="indent">2.5 Naruszanie Prywatności: Zakazane jest wysyłanie informacji poufnych
                          takich jak Imion, Nazwisk, Adresów, nr telefonów itp.</p>
                        <p className="indent">2.6 Nieodpowiednia Komunikacja: Unikaj obraźliwego, toksycznego lub
                          nieodpowiedniego języka w czatach i komunikacji z innymi graczami.</p>
                        <p className="indent">2.7 Oszustwa i Hacking: Zakazane jest wszelkie oszustwa, oszukiwanie oraz
                          używanie nielegalnych programów do hakowania gry.</p>
                        <p className="indent">2.8 Wykorzystanie PvP: Choć na serwerze jest dozwolone PvP, nie nadużywaj
                          tej możliwości i nie atakuj innych graczy bez powodu.</p>
                        <p className="indent">2.9 Zakłócanie Rozgrywki: Nie przeszkadzaj innym graczom w rozgrywce i
                          współpracy.</p>
                        <p className="indent">2.10 Nadmierne Przeszkadzanie: Zabronione jest nadmierne przeszkadzanie
                          innym graczom i zakłócanie ich rozgrywki.</p>
                        <br/>
                        <p className="center">Data opublikowania regulaminu: 31.05.2024</p>
                      </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Rules;
